
import { Component, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import { Breadcrumb } from '@/dsl/atoms/Breadcrumbs'

import { AsyncDataContext, getBreadcrumbsFactoryForRoute } from '@/shared'

import { EventCard } from '../components/organisms/EventCard'
import { EventsRepositoryType, IEventsRepository } from '../contracts'
import { EventModel, EventsGroupModel, EventsGroup as EGroup } from '../models'

@Component<EventsGroup>({
  name: 'EventsGroup',
  components: {
    EventCard,
  },
  async asyncData (context: AsyncDataContext): Promise<any> {
    const eventsRepository = context.app.$container.get<IEventsRepository>(EventsRepositoryType)
    const slug = context.params['group'] ?? ''

    const groupedEventsData = await eventsRepository.fetchGroupedEvents(slug)

    return {
      groupedEventsData
    }
  },
  mounted (): void {
    this.boot()
  },
  metaInfo (): MetaInfo {
    return {
      title: this.groupedEventsData.title,
      meta: [
        {
          name: 'og:title',
          content: this.groupedEventsData.title,
        },
        {
          vmid: 'description',
          name: 'og:description',
          content: this.groupedEventsData.description ?? '---',
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: this.groupedEventsData?.keywords ?? ''
        }
      ]
    }
  }
})
export class EventsGroup extends Vue {
  protected readonly eventsRepository: IEventsRepository =
    this.$container.get<IEventsRepository>(EventsRepositoryType)

  public groupedEventsData!: EGroup
  public groupedEvents: EventsGroupModel | null = null

  public get breadcrumbs (): Breadcrumb[] {
    if (this.$t) {
      return getBreadcrumbsFactoryForRoute(this.$route, this.title, this.$t.bind(this))
    }
    return []
  }

  private get title (): string {
    if (!this.groupedEventsData) {
      return '---'
    }

    return this.groupedEventsData.title
  }

  protected boot (): void {
    this.groupedEvents = EventsGroupModel.hydrate<EventsGroupModel>({
      title: this.groupedEventsData.title,
      description: this.groupedEventsData.description,
      lang: this.groupedEventsData.lang,
      events: this.groupedEventsData.events?.map((event: EventModel) => {
        return EventModel.hydrate(event)
      })
    })
  }
}
export default EventsGroup
